@media screen and (max-width: 600px) {

    /* LOGIN */
    .logo {
        width: 300px !important;
        margin-bottom: 0 !important;
    }

    /* HEADER */
    .header-logo {
        width: 24px !important;
        height: 24px !important;
    }

    .header-title {
        font-size: 14px !important;
        margin: 5px !important;
    }

    .header-username {
        font-size: 12px !important;
    }

    /* HOME */
    .event-container {
        margin: 0 !important;
        padding: 2 !important;
    }

    .card-event {
        width: 100% !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .title-container {
        width: 100% !important;
        margin: 0 !important;
        flex-direction: column !important;
        gap: 10px !important;
        align-items: flex-start !important;
    }

    /* EVENT DETAILS */

    .back{
        font-size: 14px !important;
    }

    .details-event-banner {
        width: 100% !important;
        object-fit: contain !important;
    }

    .details-event-content {
        width: 100% !important;
        flex-direction: column !important;
    }

    .info-content {
        width: 100% !important;
        padding: 0 !important;
        margin-top: 16px !important;
    }

    .day-container {
        flex-direction: column !important;
        margin-bottom: 20px !important;
    }

    .day-title-container{
        width: 100% !important;
        margin-bottom: 20px !important;
    }
}
